body {
  background-color: #090A0E;
  color: white;
}

.max-center {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.header {}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background-color: #0F1014;
  padding: 15px;
}

.movie-cover {
  width: 100%;
}

.movie-placeholder {
  min-height: 359px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero {
  min-height: 500px;
  background-position: top center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.hero-content {
  max-width: 70%;
  padding-bottom: 60px;
}

.hero-title {
  font-size: 72px;
  margin: 0;
}

.button {
  background-color: #0F1014;
  color: white;
  border: 1px solid white;
  outline: none;
  padding: 10px 15px;
  cursor: pointer;
}

.button--close {
  position: absolute;
  z-index: 10;
  bottom: 30px;
  left: 30px;
}

.youtube-container {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.my-info {
  height: 200px;
}

.popup-content {
  background: rgba(8, 8, 8, 0.274) !important;
}